import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import classnames from 'classnames';
import React, { Component } from 'react';

import { withNoSSR } from 'Components/NoSSR';
import AnalyticsProvider from 'common/AnalyticsProvider/AnalyticsProvider';
import { isWindow } from 'common/helpers/helpers';
import analytics from 'utils/analytics/analytics';
import localStorage from 'utils/localStorage/localStorage';

import './OnboardingTooltip.scss';

export type OnboardingTooltip$Props = {
  ruleset: string;
  className?: string;
  contentClassName?: string;
  bottom?: boolean;
  ruledata?: TOnboardingRuleData;
  countData?: TOnboardingCountData;
  action?: Function;
  entityId?: string;
  scrollDown?: () => void;
};

export type TOnboardingRuleData = {
  requestId?: string;
};
export type TOnboardingCountData = {
  providerDashboardCount?: {
    totalSent: number;
  };
};
export type TOnboardingLocalState = {
  competitionCompletedStep?: number;
  competitionCompletedCount?: number;
  activelockedCompleted?: boolean;
  afrCompleted?: boolean;
  unlockmorerequestsCompleted?: boolean;
  getmorestarsCompleted?: boolean;
  paidRequestIds?: string[];
  customerPhoneCount?: number;
  customerPhoneLastShown?: Date;
  quoteStateRequestShownRequestId?: string;
  isSetAnyQuoteState?: boolean;
  anyQuoteStateLastTime?: number;
  quoteInProgressLastTime?: number;
  quoteInProgressTimes?: number;
  quoteDoneLastTime?: number;
  quoteDoneTimes?: number;
};

export const providerCounterQuery = gql`
  query DashboardCounterQuery {
    providerDashboardCount {
      totalSent
    }
  }
`;

export const ONBOARDING_LOCAL_STORAGE_KEY = 'fixlyOnboardingState';

export const parseLocalStateJSON = (localStateString?: string | null) => {
  if (!localStateString) return {};
  let result;
  try {
    result = JSON.parse(localStateString) || {};
  } catch (e) {
    return {};
  }
  return result;
};

const validateVisibility = (
  ruleset: string,
  ruledata: TOnboardingRuleData,
  countData: TOnboardingCountData,
  localState: TOnboardingLocalState,
  entityId?: string
) => {
  const currentLocalState = parseLocalStateJSON(localStorage.getItem(ONBOARDING_LOCAL_STORAGE_KEY));
  const { providerDashboardCount: { totalSent = 0 } = {} } = countData;

  switch (ruleset) {
    case '2_see_competition': {
      const { competitionCompletedStep = totalSent, competitionCompletedCount = 0 } = localState;
      if (!totalSent || totalSent < 3 || competitionCompletedCount >= 3) return false;
      const updatedLocalState = {
        ...currentLocalState,
        competitionCompletedStep: competitionCompletedStep + 7,
        competitionCompletedCount: competitionCompletedCount + 1,
      };
      if (totalSent === competitionCompletedStep) {
        localStorage.setItem(ONBOARDING_LOCAL_STORAGE_KEY, JSON.stringify(updatedLocalState));
        return true;
      }
      return false;
    }

    case '4_bonus_request_unavailable': {
      const updatedLocalState = {
        ...currentLocalState,
        activelockedCompleted: true,
      };
      if (!localState.activelockedCompleted && totalSent && totalSent >= 3) {
        localStorage.setItem(ONBOARDING_LOCAL_STORAGE_KEY, JSON.stringify(updatedLocalState));
        return true;
      }
      return false;
    }

    case '6_improve_businesscard': {
      return totalSent >= 5;
    }

    case '7_more_requests_collapsed': {
      const updatedLocalState = {
        ...currentLocalState,
        unlockmorerequestsCompleted: true,
      };
      if (!localState.unlockmorerequestsCompleted && totalSent >= 2) {
        localStorage.setItem(ONBOARDING_LOCAL_STORAGE_KEY, JSON.stringify(updatedLocalState));
        return true;
      }
      return false;
    }

    case '8_get_more_stars': {
      const updatedLocalState = {
        ...currentLocalState,
        getmorestarsCompleted: true,
      };
      if (!localState.getmorestarsCompleted && totalSent >= 20) {
        localStorage.setItem(ONBOARDING_LOCAL_STORAGE_KEY, JSON.stringify(updatedLocalState));
        return true;
      }
      return false;
    }

    case '10_paid_request_info': {
      const { competitionCompletedStep = totalSent, competitionCompletedCount = 0, paidRequestIds = [] } = localState;
      const { requestId } = ruledata;
      const isCompetitionShows =
        totalSent && (totalSent >= 3 || competitionCompletedCount < 3) && totalSent === competitionCompletedStep;
      if (typeof totalSent === 'undefined') return false;
      if (isCompetitionShows || paidRequestIds.length >= 3 || (requestId && paidRequestIds.indexOf(requestId) !== -1)) {
        return false;
      }
      const updatedLocalState = {
        ...currentLocalState,
        paidRequestIds: [...paidRequestIds, requestId],
      };
      localStorage.setItem(ONBOARDING_LOCAL_STORAGE_KEY, JSON.stringify(updatedLocalState));
      return true;
    }

    case 'customer_phone': {
      const { customerPhoneCount, customerPhoneLastShown } = localState;

      const customerPhoneLastShownInMs = customerPhoneLastShown
        ? new Date(customerPhoneLastShown).getTime()
        : Date.now();

      const diffInMinutes = customerPhoneLastShown ? Math.round(Date.now() - customerPhoneLastShownInMs / 60000) : 0;
      const updatedLocalState = {
        ...currentLocalState,
        customerPhoneCount: customerPhoneCount ? customerPhoneCount + 1 : 1,
        customerPhoneLastShown: Date.now(),
      };

      if (!customerPhoneCount || (customerPhoneCount <= 3 && diffInMinutes > 5)) {
        localStorage.setItem(ONBOARDING_LOCAL_STORAGE_KEY, JSON.stringify(updatedLocalState));
        return true;
      }

      return false;
    }

    case '3_set_quote_state': {
      const { quoteStateRequestShownRequestId, isSetAnyQuoteState } = localState;
      if ((quoteStateRequestShownRequestId && quoteStateRequestShownRequestId !== entityId) || isSetAnyQuoteState) {
        return false;
      }
      const updatedLocalState = {
        ...currentLocalState,
        anyQuoteStateLastTime: Date.now(),
        quoteStateRequestShownRequestId: entityId,
      };
      localStorage.setItem(ONBOARDING_LOCAL_STORAGE_KEY, JSON.stringify(updatedLocalState));
      return true;
    }

    case '4_inprogress_quote_state': {
      const {
        isSetAnyQuoteState,
        anyQuoteStateLastTime = 0,
        quoteInProgressLastTime = 0,
        quoteInProgressTimes = 0,
      } = localState;
      const timeDiff = Date.now() - anyQuoteStateLastTime;
      const isDaySpend = !quoteInProgressLastTime || Date.now() - quoteInProgressLastTime > 1000 * 3600 * 24;
      if (!isSetAnyQuoteState || timeDiff < 5000 || !isDaySpend || quoteInProgressTimes > 2) return false;
      const updatedLocalState = {
        ...currentLocalState,
        quoteInProgressTimes: quoteInProgressTimes + 1,
        quoteInProgressLastTime: Date.now(),
        anyQuoteStateLastTime: Date.now(),
      };
      localStorage.setItem(ONBOARDING_LOCAL_STORAGE_KEY, JSON.stringify(updatedLocalState));
      return true;
    }

    case '5_send_to_archive': {
      const { isSetAnyQuoteState, anyQuoteStateLastTime = 0, quoteDoneLastTime = 0, quoteDoneTimes = 0 } = localState;
      const timeDiff = Date.now() - anyQuoteStateLastTime;
      const isDaySpend = !quoteDoneLastTime || Date.now() - quoteDoneLastTime > 1000 * 3600 * 24;
      if (!isSetAnyQuoteState || timeDiff < 5000 || !isDaySpend || quoteDoneTimes > 1) return false;
      const updatedLocalState = {
        ...currentLocalState,
        quoteDoneTimes: quoteDoneTimes + 1,
        quoteDoneLastTime: Date.now(),
        anyQuoteStateLastTime: Date.now(),
      };
      localStorage.setItem(ONBOARDING_LOCAL_STORAGE_KEY, JSON.stringify(updatedLocalState));
      return true;
    }

    default: {
      return true;
    }
  }
};

class OnboardingTooltip extends Component<OnboardingTooltip$Props> {
  state = {
    localOnboardingState: isWindow && parseLocalStateJSON(localStorage.getItem(ONBOARDING_LOCAL_STORAGE_KEY)),
  };

  componentDidUpdate() {
    this.props.scrollDown && this.props.scrollDown();
  }

  render() {
    if (!isWindow) return false;
    const {
      bottom,
      children,
      className,
      contentClassName,
      ruleset,
      ruledata = {},
      countData = {},
      action,
      entityId,
    } = this.props;
    const { localOnboardingState } = this.state;

    const isVisible = validateVisibility(ruleset, ruledata, countData, localOnboardingState, entityId);

    return (
      isVisible && (
        <AnalyticsProvider
          onMount={{
            eventName: 'show_tooltip',
            options: ruleset ? { touch_point_button: ruleset } : {},
          }}
        >
          <div
            role="tooltip"
            className={classnames('onboardingTooltip', { onboardingTooltip__bottom: bottom }, className)}
          >
            <div
              onClick={() => {
                if (typeof action === 'function') action();
                analytics.trackEvent('click_tooltip', ruleset ? { touch_point_button: ruleset } : {});
              }}
              role="button"
              className={classnames('onboardingTooltip__content', contentClassName)}
            >
              {children}
            </div>
          </div>
        </AnalyticsProvider>
      )
    );
  }
}

export default graphql<OnboardingTooltip$Props>(providerCounterQuery, {
  name: 'countData',
  options: { fetchPolicy: 'network-only' },
})(withNoSSR(OnboardingTooltip));
