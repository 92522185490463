import { t } from '@lingui/macro';
import { gql } from '@apollo/client';
import React, { ReactNode } from 'react';

import { DateOption } from 'common/DateSelect/DateSelect';
import SelectableItem from 'uikit/SelectableItem/SelectableItem';
import { Textarea } from 'uikit/Textarea';

import { CitySuggestion } from '../LocationSelect/LocationSearch.helpers';

declare global {
  interface Window {
    getLaquesisVariant: (experiment: string) => string; // Laquesis
    /**
     * @deprecated
     */
    isVariantEnabled: (variant: string) => boolean;
  }
}

enum FieldType {
  MULTI = 'MULTI_CHOICE',
  SINGLE = 'SINGLE_CHOICE',
  TEXT = 'TEXT',
  SYNTHETIC = 'SYNTHETIC',
}

type WizardQuestionType = FieldType.TEXT | FieldType.SINGLE | FieldType.MULTI;

export type DocumentFile = {
  name: string;
  type: string;
  url: string;
};

export type WizardAnswer = {
  id: string;
  label: string;
};

export type WizardQuestion = {
  id: string;
  label: string;
  proLabel: string;
  placeholder?: string;
  type: WizardQuestionType;
  answers: WizardAnswer[];
};

export type RequestDetailsInput = {
  questionId: string;
  question: string;
  answerId: string;
  answer: string;
  spQuestion?: string;
  files?: string[];
  documentFiles?: DocumentFile[];
};

export type RequestWizardField = {
  id: string;
  type: FieldType;
  label: string;
  name: string; // maybe is not used
  values: WizardAnswer[];
  spQuestion?: string;
  optional?: boolean;
  placeholder?: string;
};

export type RequestWizardAddress = {
  human: string;
  coords: {
    lat: number;
    lng: number;
  };
};

export type RequestWizardDate = {
  id: DateOption;
  exactDate: string;
  answer: string;
};

type IkeaProduct = {
  productId: string;
  quantity: number;
};

export type RequestWizard$Props = {
  isPreloading: boolean;
  categoryId: string;
  categorySlug: string;
  isOpen: boolean;
  openId: string;
  providersCount: number;
  fields: RequestWizardField[];
  serviceNotePlaceholder?: string;
  userName: string;
  isUserLoggedIn: boolean;
  userId?: string;
  email: string | undefined;
  phone: string | undefined;
  cityId?: string;
  cityName: string | undefined;
  neighbourhoodId?: string;
  providerId?: string;
  isUserVerified?: boolean;
  touchPointButton?: string;
  ikeaProducts?: IkeaProduct[];
  onClose: (closer?: string) => void;
  onFail?: (error: any) => void;
  createRequest: (options: any) => Promise<any>;
  isCategoryPaid: (cityId: string, l4Id: string) => Promise<boolean>;
  externalBackAction?: () => void;
};

export type RequestWizard$State = {
  currentStep: number;
  answers: RequestDetailsInput[];
  shouldRenderPreventClose: boolean;
  isLoading: boolean;
  imagesLoadingCount: number;
  requestComplete: boolean;
  requestModalCloser: string | undefined;
  images: DocumentFile[];
  requestId: number;
  userId: string;
  guestUser: boolean;
  animationComplete: boolean;
  isCodeLoginStep: boolean;
  registerType: string;
  isStaticControls: boolean;
  city: CitySuggestion | null;
  cityDistrictId?: string;
  date: RequestWizardDate | null;
  email: string;
  phone: string;
  userName: string;
  region: string;
  isCategoryPaid: boolean;
};

export interface RequestData {
  uuid: string;
  categoryId: string;
  details: RequestDetailsInput[];
  cityId: string;
  features?: {
    helpRequest?: boolean;
    helpAddress?: string;
  };
  exactDate?: string;
  serviceSchedule?: string;
  directProviderId?: string;
  /**
   * @deprecated reason: "GS-6258: L4-city-neighbourhood pages"
   */
  cityNeighbourhoodId?: string;
  cityDistrictId?: string;
  ikeaProducts?: IkeaProduct[];
}

export const districtField: RequestWizardField = {
  id: 'service-district',
  type: FieldType.SINGLE,
  label: t`W jakiej dzielnicy ma zostać wykonana usługa?`,
  name: 'service-district',
  optional: true,
  values: [],
};

const intentionField: RequestWizardField = {
  id: 'intention',
  type: FieldType.SINGLE,
  label: t`Dlaczego składasz zapytanie?`,
  name: 'intention',
  values: [
    {
      id: 'real-request',
      label: t`Mam usługę do wykonania`,
    },
    {
      id: 'price-checking',
      label: t`Potrzebuję samej wyceny usługi`,
    },
  ],
};

export function getDefaultFields(isLoggedIn: boolean, isLocationSelected: boolean): RequestWizardField[] {
  const defaultFields: RequestWizardField[] = [
    {
      id: 'service-note',
      type: FieldType.TEXT,
      label: t`A teraz opisz dokładniej, czego potrzebujesz`,
      name: t`Wpisz adres i kliknij enter`,
      values: [],
      optional: true,
    },
  ];

  defaultFields.unshift({
    id: 'service-schedule',
    type: FieldType.TEXT,
    label: t`Termin usługi`,
    name: t`Wpisz adres i kliknij enter`,
    values: [],
  });

  if (!isLocationSelected) {
    defaultFields.push({
      id: 'service-location',
      type: FieldType.SYNTHETIC,
      label: t`Gdzie usługa ma być wykonana?`,
      name: 'service-location',
      values: [],
    });
    defaultFields.push(districtField);
  }

  defaultFields.push(intentionField);

  // TODO: remove animation from fields
  defaultFields.push({
    id: 'animation',
    type: FieldType.SYNTHETIC,
    label: '',
    name: '',
    values: [],
  });

  if (isLoggedIn) {
    return defaultFields;
  }

  return [
    ...defaultFields,
    {
      id: 'email-checking',
      type: FieldType.SYNTHETIC,
      label: t`wiadomościach od zainteresowanych wykonawców poinformujemy Cię e-mailem`,
      name: 'email',
      values: [],
    },
  ];
}

export const questionToFieldMapper = (question: WizardQuestion): RequestWizardField => ({
  id: question.id,
  type: question.type,
  label: question.label,
  name: question.id,
  spQuestion: question.proLabel,
  placeholder: question.placeholder,
  values: question.answers,
});

export const renderFieldItems = (
  stepData: RequestWizardField,
  stepAnswer: RequestDetailsInput | undefined,
  changeHandler: (e: any) => void,
  focusHandler: (e: any) => void
): ReactNode => {
  const defaultPlaceholder = t`Podanie szczegółów zwiększy Twoją szansę na otrzymanie dopasowanych ofert`;

  switch (stepData.type) {
    case FieldType.TEXT: {
      return (
        <Textarea
          name={stepData.name}
          onChange={changeHandler}
          onFocus={focusHandler}
          value={stepAnswer?.answer || ''}
          placeholder={stepData.placeholder || defaultPlaceholder}
          expandable
        />
      );
    }
    case FieldType.MULTI: {
      return stepData.values.map((item) => {
        const checked = stepAnswer?.answerId.split(',').includes(item.id);
        return (
          <SelectableItem
            type="checkbox"
            key={item.id}
            isChecked={Boolean(checked)}
            changeHandler={changeHandler}
            value={item.id}
            name={`question_${stepData.id}`}
            label={item.label}
            className="requestWizard__selectableItem"
          />
        );
      });
    }
    case FieldType.SINGLE:
    default: {
      return stepData.values.map((item) => {
        const itemChecked = stepAnswer?.answerId === item.id;
        return (
          <SelectableItem
            type="radio"
            key={item.id}
            isChecked={itemChecked}
            changeHandler={changeHandler}
            value={item.id}
            name={`question_${stepData.id}`}
            label={item.label}
            className="requestWizard__selectableItem"
          />
        );
      });
    }
  }
};

export const generateServiceRequestData = (
  answers: RequestDetailsInput[],
  cityId: string,
  date: RequestWizardDate | null,
  categoryId: string,
  files: DocumentFile[],
  uuid: string,
  providerId?: string,
  cityNeighbourhoodId?: string,
  cityDistrictId?: string
) => {
  const filteredAnswers = answers.filter((answer) => answer);
  const details: RequestDetailsInput[] = filteredAnswers.map((answer) => {
    const res: RequestDetailsInput = {
      ...answer,
    };

    if (answer.questionId === 'service-note' && files.length > 0) {
      res.files = files.filter((f) => /(jpeg|png|gif)$/.test(f.type)).map((f) => f.url);
      res.documentFiles = files.filter((f) => /pdf$/.test(f.type));
    }

    return res;
  });

  const requestData: RequestData = {
    uuid: uuid,
    categoryId,
    details,
    cityId,
    cityNeighbourhoodId,
    cityDistrictId,
  };

  if (date) {
    if (date.exactDate) {
      requestData.exactDate = date.exactDate;
    } else {
      requestData.serviceSchedule = date.answer;
    }
  }

  if (providerId) {
    requestData.directProviderId = providerId;
  }

  return requestData;
};

export const IKEA_CATEGORY_ID = '2430';

export const createRequestMutation = gql`
  mutation createRequest(
    $uuid: ID!
    $categoryId: ID!
    $cityId: ID!
    $cityDistrictId: ID
    $cityNeighbourhoodId: ID
    $details: [RequestDetailsInput]
    $ikeaProducts: [RequestIkeaProductInput!]
    $directProviderId: ID
    $serviceSchedule: String
    $exactDate: String
    $features: JSONObject
  ) {
    createRequest(
      uuid: $uuid
      categoryId: $categoryId
      cityId: $cityId
      cityDistrictId: $cityDistrictId
      cityNeighbourhoodId: $cityNeighbourhoodId
      details: $details
      ikeaProducts: $ikeaProducts
      directProviderId: $directProviderId
      serviceSchedule: $serviceSchedule
      exactDate: $exactDate
      features: $features
    ) {
      id
      details {
        id
        answer
        answerId
        question
        questionId
      }
    }
  }
`;

export const isForwardDisabled = (step: RequestWizardField, answer: RequestDetailsInput | undefined): boolean => {
  if (step.optional) {
    return false;
  } else {
    if (!answer) return true;
    if (step.type === FieldType.TEXT) {
      return !answer.answerId.trim().length;
    } else {
      return !answer.answerId;
    }
  }
};

const idSeparator = ',';

const getUpdatedIds = (value: string, answer: RequestDetailsInput): string[] => {
  const ids = (answer.answerId || '').split(idSeparator);
  const checked = ids.includes(value);
  return checked ? ids.filter((item) => item !== value) : [value, ...ids];
};

export const updateAnswerId = (value: string, type: FieldType, answer: RequestDetailsInput): string => {
  if (type === FieldType.MULTI) {
    return getUpdatedIds(value, answer).join(idSeparator);
  }

  return value;
};

export const updateAnswer = (value: string, step: RequestWizardField, answer: RequestDetailsInput): string => {
  if (step.type === FieldType.SINGLE) {
    const checked = step.values.find((item) => item.id === value);
    return checked ? checked.label : '';
  }

  if (step.type === FieldType.MULTI) {
    const updatedIds = getUpdatedIds(value, answer);
    const answerLabels = step.values.filter((item) => updatedIds.includes(item.id)).map((item) => item.label);
    return answerLabels.join(', ');
  }

  return value;
};
