import { useQuery } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import PwfAdvantagesModal from 'Components/PwfAdvantagesModal/PwfAdvantagesModal';
import {
  pwfProvider,
  TpwfProviderResponse,
  TpwfVerification,
  TpwfVerificationStatus,
} from 'Components/Settings/PayWithFixly/PayWithFixly.helpers';
import VerificationStatusPanel, {
  VerificationStatus,
} from 'Components/VerificationStatusPanel/VerificationStatusPanel';
import { ReactComponent as PwFBadge } from 'common/badges/pwf.svg';
import { LinkButton } from 'uikit/Button';
import { TextMeta } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';
import routes from 'utils/routeTranslator';

import './ProviderPwF.scss';

type ProviderPwFProps = {
  className?: string;
  isRegistered?: boolean;
  editable?: boolean;
  pwfVerificationData?: TpwfVerification;
};

export const ProviderPwF: FC<ProviderPwFProps> = (props) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const isPublicEnabled = !props.editable && props.isRegistered;
  const initialStatus = isPublicEnabled ? VerificationStatus.verified : VerificationStatus.default;
  const [status, setStatus] = useState<VerificationStatus>(initialStatus);
  const { data } = useQuery<TpwfProviderResponse>(pwfProvider, {
    skip: !props.editable,
  });
  const history = useHistory();

  useEffect(() => {
    if (data) {
      const { status: pwfStatus } = data.pwfVerification;

      switch (pwfStatus) {
        case TpwfVerificationStatus.success:
        case TpwfVerificationStatus.error:
        case TpwfVerificationStatus.fullVerificationRequested:
        case TpwfVerificationStatus.inProgress:
        case TpwfVerificationStatus.rejected:
        case TpwfVerificationStatus.negative:
          setStatus(VerificationStatus.verified);
          break;
        case TpwfVerificationStatus.notRegistered:
          setStatus(VerificationStatus.default);
          break;
        default:
          break;
      }
    }
  }, [data]);

  const onAction = (): void => {
    if (!props.editable) return;
    const errorStatuses = [
      TpwfVerificationStatus.error,
      TpwfVerificationStatus.fullVerificationRequested,
      TpwfVerificationStatus.rejected,
      TpwfVerificationStatus.negative,
    ];
    const pwfStatus: any = data?.pwfVerification.status;
    if (pwfStatus === TpwfVerificationStatus.success) return setIsDetailsOpen(true);
    if (pwfStatus === TpwfVerificationStatus.notRegistered) {
      history.push({
        pathname: routes.get('provider::onboarding.pwf'),
        search: '?rel_entity_type=profile',
      });
      analytics.trackEvent('pwf_reg_start', {
        entry_point: 'profile',
      });
    }
    if (errorStatuses.includes(pwfStatus)) {
      history.push(routes.get('provider::settings.getPayWithFixly'));
    }
  };

  const getActionByPwFStatus = (status: TpwfVerificationStatus | undefined): string => {
    switch (status) {
      case TpwfVerificationStatus.success:
        return t`Szczegóły`;
      case TpwfVerificationStatus.inProgress:
        return '';
      case TpwfVerificationStatus.error:
      case TpwfVerificationStatus.fullVerificationRequested:
      case TpwfVerificationStatus.rejected:
      case TpwfVerificationStatus.negative:
        return t`Przejdź do ustawień`;
      default:
        return t`Aktywuj`;
    }
  };

  const getWarningMessage = (status: TpwfVerificationStatus | undefined): string | undefined => {
    if (status === 'success') return undefined;
    return data?.pwfVerification.message;
  };

  const onDetailsClicked = () => {
    setIsDetailsOpen(true);
    analytics.trackEvent('pwf_reg_start', {
      entry_point: 'profile - more info',
    });
  };

  if (props.editable && !data) return null;
  if (data && data.pwfVerification.status === TpwfVerificationStatus.notEligible) return null;
  const pwfStatus = data?.pwfVerification.status;

  return (
    <>
      <VerificationStatusPanel
        className={props.className}
        status={props.editable ? status : initialStatus}
        badge={<PwFBadge />}
        title={t`Płatności Fixly`}
        action={data ? getActionByPwFStatus(pwfStatus) : undefined}
        warning={props.editable ? getWarningMessage(pwfStatus) : undefined}
        onAction={onAction}
      >
        {status !== VerificationStatus.verified && status === VerificationStatus.default && (
          <TextMeta>
            <Trans>Aktywuj, aby wygodnie rozliczać się z klientami.</Trans>{' '}
            <LinkButton className="ProviderPwF__linkFeatures" onClick={() => onDetailsClicked()}>
              <Trans>Poznaj korzyści</Trans>
            </LinkButton>
          </TextMeta>
        )}
      </VerificationStatusPanel>

      {props.editable && <PwfAdvantagesModal isOpen={isDetailsOpen} handleClose={() => setIsDetailsOpen(false)} />}
    </>
  );
};
