import { useMutation, gql } from '@apollo/client';
import { Trans } from '@lingui/macro';
import React, { FC, useContext } from 'react';

import { ProviderProfileEditContext } from 'Components/DashboardProviderProfile/DashboardProviderProfile.helpers';
import ShadowText from 'Components/ShadowText/ShadowText';
import VerifiedIcon from 'Components/VerifiedIcon/VerifiedIcon';
import { useQueryParams } from 'common/hooks';
import { ReactComponent as ClearIcon } from 'common/icons/clear-circle-filled.svg';
import { ReactComponent as EnvelopeIcon } from 'common/icons/envelope.svg';
import { ReactComponent as LocationIcon } from 'common/icons/location.svg';
import { ReactComponent as PhoneIcon } from 'common/icons/phone.svg';
import { LinkButton } from 'uikit/Button';
import useSnackbar from 'uikit/Snackbar/useSnackbar';
import { TextMeta, TextP3 } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';

import './ProviderContacts.scss';

interface Props {
  email?: string;
  emailVerified?: boolean;
  phone?: string;
  phoneVerified?: boolean;
  address?: string;
  providerId: string;
  editable?: boolean;
}

type RevealPhoneVars = {
  providerId: string;
};

type RevealPhoneResponse = {
  revealProviderContactCardPhone: {
    phone?: string;
  };
};

const revealPhoneMutation = gql`
  mutation RevealPhone($providerId: ID!) {
    revealProviderContactCardPhone(providerId: $providerId) {
      phone
    }
  }
`;

const verifyEmailMutation = gql`
  mutation VerifyEmailMutation {
    resendEmailConfirmation
  }
`;

const ProviderContacts: FC<Props> = (props) => {
  const { email, phone, address, providerId } = props;
  const hasAnyContact: boolean = [email, phone, address].some((item) => Boolean(item));
  const [revealPhone] = useMutation<RevealPhoneResponse, RevealPhoneVars>(revealPhoneMutation, {
    variables: {
      providerId,
    },
  });
  const [sendVerificationEmail] = useMutation(verifyEmailMutation);
  const { showSnackbar } = useSnackbar();

  const { setId, setData } = useContext(ProviderProfileEditContext);
  const params = useQueryParams();

  const touchPointButton = params.get('touch_point_button');

  const revealFn = () => revealPhone().then((res) => res.data?.revealProviderContactCardPhone.phone || '');

  const openPhoneVerificationModal = () => {
    setData({
      touchPointButton: 'paid_profile',
    });
    setId('phone-verification');
  };

  const handleEmailVerification = async () => {
    await sendVerificationEmail().catch((error) =>
      showSnackbar({
        message: error.networkError?.message || error.message,
        variant: 'error',
      })
    );

    showSnackbar({
      content: (
        <>
          <TextP3 bold className="providerContacts__inverse">
            <Trans>Wiadomość wysłaliśmy na adres:</Trans> {email}
          </TextP3>
          <TextMeta big className="providerContacts__inverse">
            <Trans>Kliknij w link w wiadomości, aby zweryfikować adres email</Trans>
          </TextMeta>
        </>
      ),
      variant: 'success',
    });
  };

  if (!hasAnyContact) return null;

  return (
    <address className="providerContacts">
      {email && (
        <div className="providerContacts__textLine">
          <EnvelopeIcon className="providerContacts__icon" aria-hidden />
          <a href={`mailto://${email}`} className="providerContacts__shadowText">
            {email}
          </a>
          {props.emailVerified && <VerifiedIcon className="providerContacts__badge" />}
          {!props.emailVerified && props.editable && (
            <LinkButton className="providerContacts__verify" onClick={handleEmailVerification}>
              <ClearIcon width={14} height={14} aria-hidden />
              <Trans>Zweryfikuj</Trans>
            </LinkButton>
          )}
        </div>
      )}

      {phone && (
        <div className="providerContacts__textLine">
          <PhoneIcon className="providerContacts__icon" aria-hidden />
          <ShadowText
            className="providerContacts__shadowText"
            kind="phone"
            text={phone}
            hideByDefault
            onReveal={() =>
              analytics.trackEvent('provider_profile_show_phone', { touch_point_button: touchPointButton })
            }
            revealFn={revealFn}
          />
          {props.phoneVerified && <VerifiedIcon className="providerContacts__badge" />}
          {!props.phoneVerified && props.editable && (
            <LinkButton className="providerContacts__verify" onClick={openPhoneVerificationModal}>
              <ClearIcon width={14} height={14} aria-hidden />
              <Trans>Zweryfikuj</Trans>
            </LinkButton>
          )}
        </div>
      )}

      {address && (
        <div className="providerContacts__textLine">
          <LocationIcon className="providerContacts__icon" aria-hidden />
          <span className="providerContacts__shadowText">{address}</span>
        </div>
      )}
    </address>
  );
};

export default ProviderContacts;
