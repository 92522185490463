import { QueryHookOptions, useQuery, gql } from '@apollo/client';
import { t, plural } from '@lingui/macro';
import { ProfileBadgeId } from 'common/ProviderBadges/ProviderBadges';
import { escapeHtml } from 'common/helpers/helpers';
import config from 'config/config';
import routes from 'utils/routeTranslator';

type ProfileUser = {
  publicName?: string;
  feedbacksCount: number;
  city?: {
    name: string;
  };
};

export const getPublicProfileTitlePrefix = (profileUser: ProfileUser): string => {
  if (!profileUser) return '';

  const { publicName, feedbacksCount } = profileUser;

  const feedbacksString = feedbacksCount
    ? plural(feedbacksCount, {
        one: '# opinia',
        few: '# opinie',
        other: '# opinii',
      })
    : t`Opinie`;

  return `${publicName} | ${feedbacksString}`;
};

export const getPublicProfileSiteTitle = (profileUser: ProfileUser): string => {
  const fixlyLabel = t`Fixly.pl`;

  if (!profileUser) return fixlyLabel;

  return `${getPublicProfileTitlePrefix(profileUser)} | ${fixlyLabel}`;
};

export const getPublicProfileOgDescription = (user: TProviderPublicProfileData): string => {
  if (!user || !user.specializations) return '';
  const categories: string[] = user.specializations.map((item) => item.name);
  if (categories.length > 0) {
    const requestServiceLabel = t`Zapytaj o usługę`;
    return `${categories.join(', ')}. ${requestServiceLabel}`;
  }
  return '';
};

export const getCanonicalBySlug = (slug: string): string => {
  return `${config.SITE_URL}${decodeURIComponent(
    routes.get('front::user.profile', { username: decodeURIComponent(slug) })
  )}`;
};

export const getPrimarySpecialization = (
  profile: TProviderPublicProfileData
): { slug: string; name: string } | undefined => {
  if (!profile.specializations) return undefined;

  const specializations = [...profile.specializations];
  const isChecked = (item: any) => item.checked;
  specializations.sort((a, b) => {
    return a.servicesL4.filter(isChecked).length > b.servicesL4.filter(isChecked).length ? -1 : 1;
  });
  return specializations[0];
};

export const getPublicName = (profile: TProviderPublicProfileData): string => {
  const fullName = `${profile.firstName} ${profile.lastName}`.trim();
  return escapeHtml(profile.companyName ? profile.companyName : fullName);
};

export interface TProfileGalleryImage {
  order: number;
  url: string;
  title?: string;
}

export interface ServicePriceItem {
  serviceName: string;
  servicePrice: string;
}

export interface ServicePrice {
  l2CategoryId: string;
  items?: ServicePriceItem[];
}

export enum VerificationStatus {
  initial = 'INITIAL',
  inModeration = 'IN_MODERATION',
  rejected = 'REJECTED',
  verified = 'VERIFIED',
}

export type Company = {
  id: string;
  nip: string;
  name?: string;
  contactName?: string;
  address?: string;
  foundationDate?: string;
  verificationStatus: VerificationStatus;
};

export interface TProviderPublicProfileData {
  id: string;
  slug: string;
  slugAlias?: string;
  avatarUrl: string;
  coverUrl?: string;
  firstName: string;
  lastName: string;
  companyName?: string;
  publicName?: string;
  seoDescription?: string;
  feedbacksCount: number;
  feedbackClientsCount: number;
  lastActivity?: string;
  shortDescription?: string;
  longDescription?: string;
  radius?: number;
  rating: number;
  dateRegistered: string;
  searchIndexing: 'not_processed' | 'noindex_auto' | 'index_auto' | 'noindex_admin' | 'index_admin' | 'in_moderation';
  email?: string;
  emailVerified: boolean;
  maskedPhone?: string;
  phoneVerified: boolean;
  address?: string;
  websiteUrl?: string;
  socialMediaUrl?: string;
  featured: boolean;
  isNewcomer: boolean;
  isPayWithFixlyRegistered: boolean;
  company?: Company;
  insurance?: {
    title: string;
    active: boolean;
  };
  city?: {
    id: string;
    name: string;
    slug: string;
    isBig: boolean;
    lat: number;
    long: number;
    region?: {
      id: string;
      name: string;
    };
  };
  location?: {
    latitude: number;
    longitude: number;
  };
  mapUrl?: string;
  specializations?: {
    id: string;
    name: string;
    slug: string;
    servicesL4: {
      id: string;
      name: string;
      slug: string;
      checked: boolean;
    }[];
  }[];
  badges: {
    id: ProfileBadgeId;
    title: string;
    tooltip?: string;
  }[];
  gallery: TProfileGalleryImage[];
  contactCardSettings?: ContactCardSettings;
  prices?: ServicePrice[];
}

interface ProviderProfileQueryResult {
  profileBySlug: TProviderPublicProfileData;
}

export type ContactCardSettings = {
  isEmailHidden: boolean;
  isPhoneHidden: boolean;
};

export const providerPublicProfileQuery = gql`
  query ProviderProfile($slug: String!) {
    profileBySlug(slug: $slug) {
      id
      slug
      slugAlias
      avatarUrl
      coverUrl
      firstName
      lastName
      companyName
      publicName
      seoDescription
      feedbacksCount
      feedbackClientsCount
      lastActivity
      shortDescription
      longDescription
      radius
      rating
      dateRegistered
      searchIndexing
      email
      emailVerified
      maskedPhone
      phoneVerified
      address
      websiteUrl
      socialMediaUrl
      featured
      isNewcomer
      isPayWithFixlyRegistered
      company {
        id
        nip
        name
        contactName
        address
        foundationDate
        verificationStatus
      }
      insurance {
        title
        active
      }
      city {
        id
        name
        slug
        isBig
        lat
        long
        region {
          id
          name
        }
      }
      location {
        latitude
        longitude
      }
      mapUrl(scale: x2)
      specializations {
        id
        name
        slug
        servicesL4 {
          id
          name
          slug
          checked
        }
      }
      badges {
        id
        title
        tooltip
        actionUrl
        actionTitle
      }
      gallery {
        order
        url
        title
      }
      contactCardSettings {
        isEmailHidden
        isPhoneHidden
      }
      prices {
        l2CategoryId
        items {
          serviceName
          servicePrice
        }
      }
    }
  }
`;

export const useProviderPublicProfileQuery = (
  options: QueryHookOptions<ProviderProfileQueryResult, { slug: string }>
) => useQuery<ProviderProfileQueryResult, { slug: string }>(providerPublicProfileQuery, options);
