import { gql } from '@apollo/client';
import { useLocation } from 'react-router-dom';

export enum TpwfVerificationStatus {
  error = 'error',
  inProgress = 'inProgress',
  success = 'success',
  notRegistered = 'notRegistered',
  notEligible = 'notEligible',
  fullVerificationRequested = 'fullVerificationRequested',
  reverificationRequested = 'reverificationRequested',
  recoverableNegative = 'recoverableNegative',
  negative = 'negative',
  rejected = 'rejected',
}

export enum PwFVerificationPhaseEnum {
  KYC1 = 'KYC1',
  KYC2 = 'KYC2',
}

type kyc2Info = {
  hasFilledAdditionalData: boolean;
  hasUploadedDocuments: boolean;
  hasSubmittedIban: boolean;
};

export type TpwfVerification = {
  status: TpwfVerificationStatus;
  message: string;
  phase: PwFVerificationPhaseEnum;
  note?: string;
  errors?: [];
  verificationId?: string;
  kyc2Info: kyc2Info;
};

export type PwfLegalType = 'company' | 'privatePerson';

export type TpwfProviderResponse = {
  pwfVerification: TpwfVerification;
};

export const pwfProvider = gql`
  query PwfProvider {
    pwfVerification {
      status
      message
      phase
      note
      verificationId
    }
  }
`;

export const useEntryPoint = () => {
  const location = useLocation();
  const relEntityType = new URLSearchParams(location.search).get('rel_entity_type');
  if (relEntityType) return relEntityType;
  return 'settings';
};
