import { useMutation, gql } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import { useFormik } from 'formik';
import React, { useEffect, useState, useContext, FC } from 'react';

import { ProviderProfileEditContext } from 'Components/DashboardProviderProfile/DashboardProviderProfile.helpers';
import { ProviderSectionHeader } from 'Components/ProviderProfileView/ProviderSectionHeader/ProviderSectionHeader';
import { Button, LinkButton } from 'uikit/Button';
import Input from 'uikit/Input/Input';

import { getProviderProfileTitle } from '../ProviderProfileView/ProviderProfileView.helpers';
import { ProviderFeedbacksSearchInput, useProviderFeedbacksQuery } from './ProviderFeedbacks.helpers';
import './ProviderFeedbacks.scss';
import ProviderFeedbacksEmpty from './ProviderFeedbacksEmpty/ProviderFeedbacksEmpty';
import ProviderFeedbacksItem from './ProviderFeedbacksItem/ProviderFeedbacksItem';
import { ProviderFeedbacksSummary } from './ProviderFeedbacksSummary/ProviderFeedbacksSummary';
import StarFilterItem from './StarFilterItem';

interface Props {
  slug: string;
  id?: string;
  isOwnProfile?: boolean;
  editable?: boolean;
}

const ProviderFeedbacks: FC<Props> = ({ slug, id: sectionId, isOwnProfile, editable }) => {
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const { data, refetch, fetchMore, variables } = useProviderFeedbacksQuery({
    variables: {
      slug,
      offset: 0,
      limit: 3,
    },
  });
  const { setId } = useContext(ProviderProfileEditContext);

  const [markFeedbacksAsSeen] = useMutation(gql`
    mutation markFeedbacksAsSeen {
      markFeedbacksAsSeen
    }
  `);

  const formik = useFormik<ProviderFeedbacksSearchInput>({
    initialValues: {
      rating: [],
      l4CategoryIds: [],
      text: '',
    },
    onSubmit: () => {},
  });
  const { values } = formik;

  useEffect(() => {
    if (isOwnProfile) {
      markFeedbacksAsSeen();
    }
  }, [isOwnProfile, markFeedbacksAsSeen]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      refetch({
        ...variables,
        searchInput: {
          rating: values.rating,
          l4CategoryIds: values.l4CategoryIds,
          text: values.text,
        },
      }).then((res) => {
        if (res.data.providerFeedbacksBySlug.length >= 3) setHasMore(true);
      });
      setOffset(0);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.rating, values.l4CategoryIds, values.text]);

  const shouldHide = data?.providerFeedbacksBySlug && data?.providerFeedbacksBySlug?.length < 3;
  useEffect(() => {
    if (shouldHide) {
      setHasMore(false);
    }
  }, [shouldHide]);

  const handleLoadMore = () => {
    const newOffset = offset + 3;
    fetchMore({
      variables: {
        ...variables,
        offset: newOffset,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult?.providerFeedbacksBySlug?.length) {
          setHasMore(false);

          return prev;
        }
        return {
          ...prev,
          providerFeedbacksBySlug: [...prev.providerFeedbacksBySlug, ...fetchMoreResult.providerFeedbacksBySlug],
        };
      },
    });
    setOffset(newOffset);
  };

  if (!data) return null;
  const {
    profileBySlug: {
      rating,
      feedbacksCount,
      feedbackClientsCount,
      feedbackInfo,
      id,
      firstName,
      lastName,
      companyName,
      avatarUrl,
    },
    providerFeedbacksBySlug,
  } = data;
  const { ratePercentages, topL4Categories } = feedbackInfo;

  const title = t`Opinie o "${getProviderProfileTitle(firstName, lastName, companyName)}"`;

  return (
    <section className="providerFeedbacks">
      <ProviderSectionHeader
        title={title}
        count={feedbacksCount}
        editButton={
          editable &&
          sectionId && (
            <LinkButton onClick={() => setId(sectionId)}>
              <Trans>Zdobądź opinie</Trans>
            </LinkButton>
          )
        }
      />
      <div className="providerFeedbacks__disclaimer">
        <Trans>
          Opinie pochodzą od osób, które nawiązały kontakt z Wykonawcą. Mogą one dotyczyć realizacji usług, przebiegu
          wizyty lub interakcji z Wykonawcą.
          <br />
          <br />
          Fixly nie weryfikuje czy opinie pochodzą od użytkowników, którzy skorzystali z danej usługi.’
        </Trans>
      </div>
      {feedbacksCount !== 0 && (
        <>
          <div className="providerFeedbacks__header">
            <div className="providerFeedbacks__headerSummaryBlock">
              <ProviderFeedbacksSummary count={feedbacksCount} clientsCount={feedbackClientsCount} rating={rating} />
            </div>
            <div className="providerFeedbacks__headerStars">
              {ratePercentages.map((star) => {
                const isEnabled = values.rating.indexOf(star.rate) !== -1;
                return (
                  <StarFilterItem
                    onChange={() =>
                      formik.setFieldValue(
                        'rating',
                        isEnabled ? values.rating.filter((i) => i !== star.rate) : [...values.rating, star.rate]
                      )
                    }
                    checked={isEnabled}
                    rate={star.rate}
                    percentage={star.percentage}
                    key={star.rate}
                  />
                );
              })}
            </div>
          </div>
          <div className="providerFeedbacks__disclaimer">
            <Trans>
              Opinie pochodzą od osób, które nawiązały kontakt z Wykonawcą. Mogą one dotyczyć realizacji usług,
              przebiegu wizyty lub interakcji z Wykonawcą.
              <br />
              <br />
              Fixly nie weryfikuje czy opinie pochodzą od osób, które skorzystały z danej usługi.
            </Trans>
          </div>
          <div className="providerFeedbacks__categoryFilter">
            <Button
              kind={values.l4CategoryIds.length || values.rating.length || values.text.length ? 'secondary' : 'primary'}
              key="all"
              onClick={() => formik.resetForm()}
            >
              <Trans>Wszystkie</Trans> • {feedbacksCount}
            </Button>
            {topL4Categories.map((category) => {
              const isEnabled = values.l4CategoryIds.indexOf(category.id) !== -1;
              return (
                <Button
                  kind={isEnabled ? 'primary' : 'secondary'}
                  key={category.id}
                  onClick={() =>
                    formik.setFieldValue(
                      'l4CategoryIds',
                      isEnabled
                        ? values.l4CategoryIds.filter((i) => i !== category.id)
                        : [...values.l4CategoryIds, category.id]
                    )
                  }
                >
                  {category.name}
                </Button>
              );
            })}
          </div>
          <div className="providerFeedbacks__textSearch">
            <Input
              value={values.text}
              onChange={(e) => formik.setFieldValue('text', e.currentTarget.value)}
              placeholder={t`Wyszukaj po mieście, kategorii lub treści opinii`}
            />
          </div>
        </>
      )}
      {!providerFeedbacksBySlug.length ? (
        <ProviderFeedbacksEmpty />
      ) : (
        <div className="providerFeedbacks__results">
          <div>
            {providerFeedbacksBySlug.map((feedback) => (
              <ProviderFeedbacksItem
                feedback={feedback}
                provider={{
                  id,
                  firstName,
                  avatarUrl,
                }}
                key={feedback.id}
              />
            ))}
          </div>
          {hasMore && (
            <div className="providerFeedbacks__pagination">
              <LinkButton onClick={handleLoadMore}>
                {values.l4CategoryIds.length || values.rating.length || values.text.length ? (
                  <Trans>Zobacz pozostałe opinie</Trans>
                ) : (
                  <Trans>Zobacz pozostałe opinie ({feedbacksCount - providerFeedbacksBySlug.length})</Trans>
                )}
              </LinkButton>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default ProviderFeedbacks;
