import { useQuery, gql } from '@apollo/client';
import { t } from '@lingui/macro';
import React from 'react';

import { escapeHtml } from 'common/helpers/helpers';

import { getPublicName, TProviderPublicProfileData } from './SPPublicProfile.helpers';

interface TSchemaFeedback {
  id: string;
  createdAt: string;
  authorFirstName: string;
  categoryName: string;
  comment: string;
  rating: string;
}

interface Props {
  spProfile: TProviderPublicProfileData;
  slug: string;
}

const providerFeedbacksBySlugQuery = gql`
  query providerFeedbacksBySlug($slug: String!, $limit: Int) {
    providerFeedbacksBySlug(slug: $slug, limit: $limit) {
      id
      createdAt
      authorFirstName
      categoryName
      comment
      rating
    }
  }
`;

// See: https://schema.org/HomeAndConstructionBusiness
const SPPublicProfileBusinessSchema = ({ spProfile, slug }: Props) => {
  const { data } = useQuery<{ providerFeedbacksBySlug: TSchemaFeedback[] }>(providerFeedbacksBySlugQuery, {
    variables: {
      slug,
      limit: 3,
    },
  });

  const profileFeedbacks = data?.providerFeedbacksBySlug || [];

  const feedbacks = profileFeedbacks.map((feedback) => ({
    '@type': 'Review',
    'datePublished': feedback.createdAt,
    'author': {
      '@type': 'Person',
      'name': feedback.authorFirstName,
    },
    'name': feedback.categoryName,
    'description': feedback.comment ? escapeHtml(feedback.comment.replace(/(?:\r\n|\r|\n)/g, ' ')) : '',
    'reviewRating': {
      '@type': 'Rating',
      'bestRating': '5',
      'ratingValue': feedback.rating,
      'worstRating': '1',
    },
  }));

  const providerReviews: any = {};
  if (feedbacks.length > 0 && spProfile.rating > 0) {
    providerReviews.review = feedbacks;
    providerReviews.aggregateRating = {
      '@type': 'AggregateRating',
      'ratingValue': spProfile.rating,
      'reviewCount': spProfile.feedbacksCount,
    };
  }

  const providerAddress: any = {};

  if (spProfile.city) {
    providerAddress.areaServed = {
      '@type': 'Place',
      'address': spProfile.city.name,
    };
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'LocalBusiness',
          'name': getPublicName(spProfile),
          'description': spProfile.shortDescription ? escapeHtml(spProfile.shortDescription) : '',
          'image': spProfile.avatarUrl,
          'telephone': '-',
          'priceRange': t`Zapytaj wykonawców o cenę`,
          ...providerReviews,
          ...providerAddress,
        }),
      }}
    />
  );
};

export default SPPublicProfileBusinessSchema;
