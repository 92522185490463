import { useMutation, gql } from '@apollo/client';
import { Trans, t } from '@lingui/macro';
import React, { useState } from 'react';

import { removeExtraLineBreaks } from 'common/helpers/helpers';
import { Button, LinkButton } from 'uikit/Button';
import { TextP1 } from 'uikit/Text';
import { TextareaWithCounter } from 'uikit/Textarea';

import { PublicProfileFeedback } from '../ProviderFeedbacks.helpers';

interface Props {
  feedbackId: string;
  setOptimisticReply: (reply: PublicProfileFeedback['reply']) => void;
}

const ReplyForm = ({ feedbackId, setOptimisticReply }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState('');
  const [submit] = useMutation<
    {
      addProviderFeedbackReply: string;
    },
    { feedbackId: string; comment: string }
  >(gql`
    mutation addProviderFeedbackReply($feedbackId: ID!, $comment: String!) {
      addProviderFeedbackReply(feedbackId: $feedbackId, comment: $comment)
    }
  `);

  const handleSubmit = async () => {
    try {
      const res = await submit({
        variables: {
          feedbackId,
          comment: removeExtraLineBreaks(text),
        },
      });
      const currentTime = new Date().toISOString();
      const id = res.data?.addProviderFeedbackReply;
      if (id) {
        setOptimisticReply({
          id,
          comment: removeExtraLineBreaks(text),
          status: 'waiting_for_moderation',
          createdAt: currentTime,
          updatedAt: currentTime,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="feedbackItemReplyForm">
      {!isOpen && (
        <LinkButton onClick={() => setIsOpen(true)}>
          <Trans>Dodaj komentarz</Trans>
        </LinkButton>
      )}
      {isOpen && (
        <div>
          <TextP1>
            <Trans>Dodaj komentarz</Trans>
          </TextP1>
          <TextareaWithCounter
            onChange={setText}
            value={text}
            minLength={3}
            maxLength={600}
            className="feedbackItemReplyForm__input"
            placeholder={t`Napisz komentarz…`}
          />
          <div className="feedbackItemReplyForm__actions">
            <Button onClick={() => setIsOpen(false)} kind="secondary">
              <Trans>Zamknij</Trans>
            </Button>
            <Button onClick={handleSubmit} kind="primary" disabled={!text}>
              <Trans>Wyślij</Trans>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReplyForm;
