import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useCallback, useEffect, useState } from 'react';

import useSnackbar from 'uikit/Snackbar/useSnackbar';
import analytics from 'utils/analytics/analytics';
import auth from 'utils/auth/auth';
import localStorage from 'utils/localStorage/localStorage';
/* @ts-expect-error no types */
import uuid from 'utils/uuid/uuid';

import RequestWizard from './RequestWizard.async';
import {
  createRequestMutation,
  getDefaultFields,
  questionToFieldMapper,
  RequestWizardField,
  districtField,
} from './RequestWizard.helpers';
import {
  WizardOpener,
  isCategoryPaid,
  getProvidersCount,
  getTouchPointButton,
  CurrentUserResponse,
  currentUserQuery,
  L4QuestionsResponse,
  l4QuestionsQuery,
} from './WizardWrap.helpers';

type Props = {
  categorySlug: string;
  cityId?: string;
  cityName?: string;
  neighbourhoodId?: string;
  providerId?: string;
  requestLocation?: {
    lat: number;
    lng: number;
  };
  ikeaProducts?: { productId: string; quantity: number }[];
  skipDistrictStep?: boolean;
  externalBackAction?: () => void;
};

const WizardWrap: FC<Props> = (props) => {
  const [wizardFlowId, setWizardFlowId] = useState<string | null>(null);
  const [providersCount, setProvidersCount] = useState<number>(0);
  const [providerId, setProviderId] = useState<string | null>(null);
  const [initialTPB, setInitialTPB] = useState<string>('');
  const { data, loading } = useQuery<L4QuestionsResponse>(l4QuestionsQuery, {
    variables: {
      slug: props.categorySlug,
    },
  });
  const { data: currentUserData } = useQuery<CurrentUserResponse>(currentUserQuery, {
    ssr: false,
    skip: !auth.isLoggedIn(),
  });

  const [createRequest] = useMutation(createRequestMutation);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    const providerId = localStorage.getItem('DIRECT_PROVIDER_ID');

    if (providerId) {
      setProviderId(providerId);
      localStorage.removeItem('DIRECT_PROVIDER_ID');
    }
  }, []);

  const analyticsWizardStart = useCallback(() => {
    const touchPointButton = getTouchPointButton();
    if (touchPointButton) setInitialTPB(touchPointButton);
    if (wizardFlowId) analytics.user.serviceRequestStart(true, touchPointButton, wizardFlowId);
    analytics.user.serviceRequestWizardStart();
  }, [wizardFlowId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const openWizard = (): void => {
    const { requestLocation } = props;
    const l4BySlug = data?.l4BySlug;

    if (requestLocation && requestLocation.lat && requestLocation.lng && l4BySlug) {
      getProvidersCount(l4BySlug.id, requestLocation).then(setProvidersCount);
    }

    setWizardFlowId(uuid());
  };

  useEffect(() => {
    const listenWizardOpener = (e: { data: WizardOpener; origin: string }): void => {
      if (e.origin === window.location.origin && e.data && e.data.type === 'open-wizard') {
        openWizard();
      }
    };

    window.addEventListener('message', listenWizardOpener);
    return () => window.removeEventListener('message', listenWizardOpener);
  }, [openWizard]);

  useEffect(() => {
    if (wizardFlowId && data && data.l4BySlug.id) {
      analyticsWizardStart();
    }
  }, [analyticsWizardStart, data, wizardFlowId]);

  if (!data) return null;

  const getWizardFields = (): RequestWizardField[] => {
    const isUserLoggedIn = auth.isLoggedIn();
    const citySelected = Boolean(props.cityId);
    const fields = [
      ...data.l4BySlug.questions.map(questionToFieldMapper),
      ...getDefaultFields(isUserLoggedIn, citySelected),
    ];
    if (!props.skipDistrictStep && citySelected) fields.unshift(districtField);

    return fields;
  };

  const closeWizard = (closer = 'unknown'): void => {
    setWizardFlowId(null);
    analytics.user.serviceRequestClose(closer);
  };

  const showErrorMessage = (message: string): void => {
    showSnackbar({ message, variant: 'error' });
  };

  const user = currentUserData?.me;
  const isUserVerified = user?.emailVerified || user?.phoneVerified;
  const userName = user ? `${user.firstName} ${user.lastName}` : '';

  if (!wizardFlowId) return null;

  return (
    <div className="wizardWrap">
      <RequestWizard
        isOpen={Boolean(wizardFlowId)}
        openId={wizardFlowId}
        categoryId={data.l4BySlug.id}
        serviceNotePlaceholder={data.l4BySlug.serviceNotePlaceholder}
        providerId={props.providerId || providerId || undefined}
        cityId={props.cityId}
        cityName={props.cityName}
        neighbourhoodId={props.neighbourhoodId}
        isPreloading={loading}
        createRequest={createRequest}
        providersCount={providersCount}
        categorySlug={props.categorySlug}
        isUserVerified={isUserVerified}
        userName={userName}
        userId={user?.id}
        email={user?.email}
        phone={user?.phone}
        isUserLoggedIn={auth.isLoggedIn()}
        externalBackAction={props.externalBackAction}
        onClose={closeWizard}
        onFail={(error) => showErrorMessage(error.networkError?.result?.errors[0].message || error.message)}
        fields={getWizardFields()}
        isCategoryPaid={isCategoryPaid}
        ikeaProducts={props.ikeaProducts}
        touchPointButton={initialTPB}
      />
    </div>
  );
};

export default WizardWrap;
