import { useQuery } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC, useContext } from 'react';

import { ProviderProfileEditContext } from 'Components/DashboardProviderProfile/DashboardProviderProfile.helpers';
import ProCardMessage from 'common/Chat/CustomMessages/ProCardMessage/ProCardMessage';
import { TProviderPublicProfileData } from 'pages/SPPublicProfile/SPPublicProfile.helpers';
import { TextP3 } from 'uikit/Text';

import { ProviderSectionHeader } from '../ProviderSectionHeader/ProviderSectionHeader';
import { currentUserPhoneQuery, PhoneResponse } from './ProviderCardView.helpers';
import './ProviderCardView.scss';

type Props = {
  className?: string;
  premium?: boolean;
  profile: TProviderPublicProfileData;
};

const ProviderCardView: FC<Props> = (props) => {
  const profile = props.profile;

  const { data } = useQuery<PhoneResponse>(currentUserPhoneQuery);
  const { setId, setData } = useContext(ProviderProfileEditContext);

  const fallbackFullName = `${profile.firstName} ${profile.lastName}`;

  const { city } = profile;
  const cityName = city?.name;
  const regionName = city?.region?.name;
  const address = [cityName, regionName].join(', ');
  const openPhoneVerificationModal = () => {
    setData({
      touchPointButton: 'profile',
    });
    setId('phone-verification');
  };

  const hostCN = classnames('providerCardView', props.className);

  return (
    <div className={hostCN}>
      <ProviderSectionHeader title={t`Wizytówka`} />
      <TextP3 className="providerCardView__lead">
        <Trans>Dobra wizytówka to więcej zleceń. Wysyłamy ją do każdego klienta, z którym nawiążesz kontakt.</Trans>
      </TextP3>
      <div className="providerCardView__body">
        <ProCardMessage
          fullName={profile.publicName || fallbackFullName}
          address={address}
          feedbackClientsCount={profile.feedbackClientsCount}
          feedbacksCount={profile.feedbacksCount}
          rating={profile.rating}
          avatarUrl={profile.avatarUrl}
          featured={profile.featured}
          newcomer={profile.isNewcomer}
          badges={profile.badges}
          shortDescription={profile.shortDescription}
          gallery={profile.gallery}
          phoneNumber={data?.me.phone}
          phoneVerified={profile.phoneVerified}
          onPhoneVerify={openPhoneVerificationModal}
          slug={profile.slug}
          slugAlias={profile.slugAlias}
          premium={props.premium}
          isProvider
          editable
        />
      </div>
    </div>
  );
};

export default ProviderCardView;
