import { gql } from '@apollo/client';
import { t } from '@lingui/macro';
import * as Yup from 'yup';

import { ServicePrice, ServicePriceItem } from 'pages/SPPublicProfile/SPPublicProfile.helpers';

export const getServicePriceBySpecialization = (id: string, prices?: ServicePrice[]): ServicePrice | undefined => {
  if (!prices) return;

  const servicePrice = prices.find((priceItem) => {
    return priceItem.l2CategoryId === id;
  });
  if (servicePrice) {
    return {
      l2CategoryId: id,
      items: servicePrice.items ? normalizeServicePriceItems(servicePrice.items) : undefined,
    };
  }
};

/**
 * This function removes extra fields from ServicePriceItem,
 * e.g "__typename"
 */
export const normalizeServicePriceItems = (items: ServicePriceItem[]): ServicePriceItem[] =>
  items.map((item) => {
    return {
      serviceName: item.serviceName,
      servicePrice: item.servicePrice,
    };
  });

const maxLength = {
  service: 64,
  price: 16,
};

export const servicePriceItemValidationSchema = Yup.object().shape({
  service: Yup.string()
    .required(t`To pole jest wymagane`)
    .max(maxLength.service, t`Pole zawierać może maksymalnie ${maxLength.service} znaków`),
  price: Yup.string()
    .required(t`To pole jest wymagane`)
    .max(maxLength.price, t`Pole zawierać może maksymalnie ${maxLength.price} znaków`),
});

export type ProviderPricesResponse = {
  profileBySlug: {
    id: string;
    prices?: ServicePrice[];
    specializations?: {
      id: string;
      name: string;
      slug: string;
    }[];
  };
};

export type ServicePricesInput = {
  prices: ServicePrice[];
};

export const providerPricesQuery = gql`
  query ProviderPrices($slug: String!) {
    profileBySlug(slug: $slug) {
      id
      prices {
        l2CategoryId
        items {
          serviceName
          servicePrice
        }
      }
      specializations {
        id
        name
        slug
      }
    }
  }
`;

export const updateProviderPricesMutation = gql`
  mutation UpdateProviderPrices($prices: [UpdateProviderPricesInput]!) {
    updateProviderPrices(prices: $prices)
  }
`;
